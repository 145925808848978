<template>
  <!--
    The view for the EventMovie-component.
  -->
  <div class="eventMovieView">
    <EventMovie :installation-id="installationId" />
  </div>
</template>

<script>
export default {
  name: 'EventMovieView',
  components: {
    EventMovie: () => import('@/components/EventMovie.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('eventMovie')
    }
  }
}
</script>
